import React from 'react'

const Logo = () => (
  <svg width="50" height="36" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <path
        d="M3.632 28.795l10.17-10.99 6.628 7.41 14.183-14.007c3.38 8.353.05 17.918-7.786 22.366-7.836 4.449-17.756 2.405-23.195-4.78zm-2.118-3.632C-2.132 16.747 1.146 6.935 9.12 2.4 17.092-2.133 27.2.066 32.57 7.501L20.647 19.393l-6.776-7.57-12.357 13.34z"
        fill={"rgb(141, 106, 159)"}
      />
    </g>
  </svg>
)

export default Logo
