import React, { useState } from 'react'
import theme from '../lib/theme'
import Text from './Text'

const phaseTitles = {
  'R1': "Round 1",
  'R2': "Round 2",
  'JO': "Jump-off",
}

const CompetitionNav = ({ competitionId, phase }) => {
  const [selectedTab] = useState(
    parseInt(localStorage.getItem('selectedTab')) || 0
  )

  let viewDescription

  switch (selectedTab) {
    case 1:
      viewDescription = 'Impact on win chance'
      break
    case 2:
      viewDescription = 'Fan voting'
      break
    default:
      viewDescription = 'Percentage win chance'
  }

  return (
    <Text
      tag="h2"
      size={[theme.fontScale.x4]}
      weight={theme.fontWeights.bold}
      lineHeight={theme.lineHeights.heading}
    >
      {`${phaseTitles[phase] || phase} - ${viewDescription}`}
    </Text>
  )
}

export default CompetitionNav
