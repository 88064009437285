import React from 'react'
import { Link } from '@reach/router'
import styled, { withTheme } from 'styled-components'

import { Logo } from './Icons'
import Text from './Text'

const HeaderContainer = styled.header`
  width: 100%;
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.x3};
  display: flex;
  align-items: center;
`

const LogoLink = ({ embed, children }) =>
  embed ? (
    <a href="https://www.equiratings.com" target="_blank" rel="noopener noreferrer">{children}</a>
  ) : (
    <Link to={embed ? '' : '/'} aria-label="Link to EquiRatings" style={{ display: 'flex' }}>
      {children}
    </Link>
  )

const Header = ({ theme, embed }) => (
  <HeaderContainer role="banner">
    <LogoLink embed={embed}>
      <Logo />
    </LogoLink>
    <Text
      tag="h1"
      size={[theme.fontScale.x5, theme.fontScale.x4]}
      weight={theme.fontWeights.bold}
      lineHeight={theme.lineHeights.heading}
    >
      Jumping Prediction Centre
    </Text>
  </HeaderContainer>
)

export default withTheme(Header)
